import React from 'react'
import '@js-joda/timezone'
import { queryServer } from '../utils/queryServer'
import {
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    ModalFooter,
    Button,
} from 'reactstrap'
import { EotPartAndRendered } from "./SolveEot"
import { useAllowedIsbns } from "../FindExercisePage/useAllowedIsbns"
import {
    IsbnAreaParsed,
    IsbnParsed,
    VideoMetadata,
    _difficulty,
    Difficulty,
    SaveExercise__Helper1,
} from '../utils/Common/Common'
import { SelectIsbnBox } from '../FindExercisePage/SelectIsbnBox'
import { check, checkNotNull } from '../utils/utilFunctions'
import * as _ from "lodash"
import { ModalContentProps } from '../utils/useModal'
import { calculateKeywordsMap } from '../HomePage/FindExercise'
import { EotTextInList } from '../utils/EotTextInList'
import { useLocalLanguage } from '../utils/ServiceLocal'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'

export type SaveExerciseModalParams = {
    eotText: EotPartAndRendered[]
    eotSolution: EotPartAndRendered[]
    videoSolution: VideoMetadata | null
    editExercise: RequestResponseTypes["getExerciseWithMetadata"]["response"] | null
}

type SelectedIsbnAndArea = {
    isbn: IsbnParsed
    area: {
        id: string
        title: string
    } | null
}

export const SaveExerciseModal = ({
    params,
    closeModal,
}: ModalContentProps<SaveExerciseModalParams>) => {
    const {
        eotSolution,
        eotText,
        videoSolution,
        editExercise,
    } = params

    const { inLocalLanguage } = useLocalLanguage()

    const allIsbns = useAllowedIsbns()
    const [subjectsAuthority, setSubjectsAuthority] = React.useState<RequestResponseTypes["getSubjectsAuthority"]["response"]>()

    const getSubjectsAuthority = async () => {
        const result = await queryServer(
            'getSubjectsAuthority',
            undefined,
        )

        setSubjectsAuthority(result)
    }

    React.useEffect(() => {
        getSubjectsAuthority()
    }, [])

    //
    // additional information down
    const [selectedIsbnAndArea, setSelectedIsbnAndArea] = React.useState<SelectedIsbnAndArea>()
    const [subject, setSubject] = React.useState<{ subjectId: string, subjectInLocal: string }>()
    const [difficulty, setDifficulty] = React.useState<Difficulty>()
    const [solvingTimeInMinutes, setSolvingTimeInMinutes] = React.useState<number>()
    const [onPage, setOnPage] = React.useState<number>()
    const [orderNumberInBook, setOrderNumberInBook] = React.useState<number>()
    // additionalKeywords should be separated by 1 space
    const [additionalKeywords, setAdditionalKeywords] = React.useState('')
    // additional information up
    //

    const setAdditionalKeywordsHelper = (value: string) => {
        setAdditionalKeywords(value)
        setCheckedKeywordReport(undefined)
        setSuggestedKeywordsReport(undefined)
    }

    const [keywordToCheck, setKeywordToCheck] = React.useState('')
    const setKeywordToCheckHelper = (value: string) => {
        setKeywordToCheck(value.trim())
        setCheckedKeywordReport(undefined)
    }
    const [checkedKeywordReport, setCheckedKeywordReport] = React.useState<{
        checkedKeyword: string
        nAppearings: number
    }>()

    const [toSuggestKeywordsFrom, setToSuggestKeywordsFrom] = React.useState('')
    const [suggestedKeywordsReport, setSuggestedKeywordsReport] = React.useState<string>()

    const [ready, setReady] = React.useState(false)

    React.useEffect(() => {
        if (allIsbns != null && subjectsAuthority != null) {
            // fulfill state if there is read data. This should be checked if it is ok n!!!!!
            if (editExercise != null) {
                setDifficulty(editExercise.exerciseMetadata.difficulty)
                setSolvingTimeInMinutes(editExercise.exerciseMetadata.solving_time_in_minutes)
                setOnPage(editExercise.exerciseMetadata.on_page_in_isbn)
                setOrderNumberInBook(editExercise.exerciseMetadata.order_number_in_isbn)
                setAdditionalKeywordsHelper(editExercise.exerciseMetadata.additional_keywords)

                // find isbn that holds editExercise.exerciseMetadata.isbn_area_id
                const isbn = allIsbns.find(it => it.areas.find(area => area.id === editExercise.exerciseMetadata.isbn_area_id) != null)
                check(isbn != null, 'WEYt6Yu8')
                setSelectedIsbnAndArea({
                    isbn,
                    area: checkNotNull(isbn.areas.find(area => area.id === editExercise.exerciseMetadata.isbn_area_id), 'hJHJJ8u bad. This is already found'),
                })

                // find subject title
                const subject = subjectsAuthority.find(s => s.subjectId === editExercise.exerciseMetadata.subject_id)
                check(subject != null, 'osIHAdh8')
                setSubject({
                    subjectId: subject.subjectId,
                    subjectInLocal: subject.subjectInLocal,
                })
            }

            setReady(true)
        }
    }, [allIsbns != null && subjectsAuthority != null])

    const okFulfilled =
        eotText.length > 0 &&
        (
            eotSolution.length > 0 ||
            videoSolution && videoSolution.videoId.length > 0
        ) &&
        selectedIsbnAndArea?.isbn != null &&
        selectedIsbnAndArea.area != null &&
        subject != null &&
        difficulty != null &&
        solvingTimeInMinutes != null && solvingTimeInMinutes > 0 &&
        onPage != null && onPage > 0 &&
        orderNumberInBook != null && orderNumberInBook > 0

    const saveExercise = async () => {
        if (
            eotText.length > 0 &&
            (
                eotSolution.length > 0 ||
                videoSolution && videoSolution.videoId.length > 0
            ) &&
            selectedIsbnAndArea?.isbn != null &&
            selectedIsbnAndArea.area != null &&
            subject != null &&
            difficulty != null &&
            solvingTimeInMinutes != null && solvingTimeInMinutes > 0 &&
            onPage != null && onPage > 0 &&
            orderNumberInBook != null && orderNumberInBook > 0
        ) {
            const data1: SaveExercise__Helper1 = {
                difficulty,
                solving_time_in_minutes: solvingTimeInMinutes,
                subject_id: subject.subjectId,
                exercise_text_public: eotText,
                additional_keywords: additionalKeywords.trim(),
                solution: eotSolution,
                // video_solution should be already checked before opening modal.
                // But check one more time just in case (for now, later will be improved logic)
                // The logic should be better: It should be checked if video really exist (on youtube) <- will be done in the future
                video_solution: videoSolution != null && videoSolution.videoId.length > 0 ? videoSolution : null,
                in_isbn: selectedIsbnAndArea.isbn.isbn,
                in_area_id: selectedIsbnAndArea.area.id,
                on_page: onPage,
                order_number_in_book: orderNumberInBook,
                exercise_text_photo_url: null,
            }

            let request: RequestResponseTypes["saveExercise"]["request"]
            if (editExercise != null) {
                request = {
                    ...data1,
                    action: 'edit',
                    exerciseId: editExercise.exerciseMetadata.id,
                }
            } else {
                request = {
                    ...data1,
                    action: 'save_new',
                }
            }

            await queryServer(
                'saveExercise',
                request,
            )

            // reload just in case so all old data will be removed
            location.reload()
        } else {
            console.log('Something not OK J8ds9UWi')
        }
    }

    const renderIsbnAreas = (isbnAreas: IsbnAreaParsed[]) => {
        return <div className="rendered-isbn-organization">
            {_.sortBy(isbnAreas, (isbnArea) => isbnArea.orderNum)
                .map(area => <div key={area.id} style={{ marginLeft: `${area.deepLevel * 10 + 10}px` }}>
                    <span
                        onClick={() => setSelectedIsbnAndArea({
                            ...selectedIsbnAndArea!,
                            area: {
                                id: area.id, title: area.title
                            }
                        })}
                        style={{ color: selectedIsbnAndArea?.area?.id === area.id ? 'green' : 'black' }}
                    >
                        {area.title}
                    </span>
                </div>)}
        </div>
    }

    let content
    if (!ready) {
        content = <h2>Please wait..</h2>
    } else {
        content = <>
            <ModalHeader toggle={closeModal}>Additional info</ModalHeader>
            <ModalBody>
                <Form>
                    <div style={{
                        border: "2px solid blue",
                        padding: "10px 5px",
                        margin: "0 0 30px 0",
                        borderRadius: "10px",
                    }}>
                        <h6 style={{ color: "blue" }}>
                            Chose exercise from book which has most similarities with solved exercise
                        </h6>
                        <FormGroup>
                            <div className="isbn-area-chose">
                                <SelectIsbnBox
                                    allIsbns={checkNotNull(allIsbns, 'sdai872')}
                                    selectedIsbn={selectedIsbnAndArea?.isbn}
                                    selectIsbn={isbnParsed => setSelectedIsbnAndArea({
                                        isbn: isbnParsed,
                                        area: null
                                    })}
                                />
                                {/* {selectedIsbnAndArea?.isbn && <h5>{selectedIsbnAndArea.isbn.bookTitle} (isbn: {selectedIsbnAndArea.isbn.isbn})</h5>} */}
                                {selectedIsbnAndArea?.isbn && renderIsbnAreas(selectedIsbnAndArea.isbn.areas)}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="isbn-area-details">
                                <span style={{ color: "gray", fontSize: "90%" }}>Chosen book:</span> {selectedIsbnAndArea?.isbn.bookTitle || '/'} (isbn: {selectedIsbnAndArea?.isbn.isbn || '/'})
                            </div>
                            <div className="isbn-area-details">
                                <span style={{ color: "gray", fontSize: "90%" }}>Chosen area:</span> {selectedIsbnAndArea?.area?.title || '/'}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>On page</Label>
                            <Input
                                type="number"
                                value={onPage ?? "On page..."}
                                onChange={e => {
                                    // bad checks. Should be fixed in the future!!!
                                    if (onPage == null && e.target.value === '0') {
                                        setOnPage(undefined)
                                    } else if (e.target.value.length > 0) {
                                        setOnPage(+e.target.value)
                                    } else {
                                        setOnPage(undefined)
                                    }
                                }}
                                placeholder="On page..."
                                onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Order number in book</Label>
                            <Input
                                type="number"
                                value={orderNumberInBook ?? "Order number in book..."}
                                onChange={e => {
                                    // bad checks. Should be fixed in the future!!!
                                    if (orderNumberInBook == null && e.target.value === '0') {
                                        setOrderNumberInBook(undefined)
                                    } else if (e.target.value.length > 0) {
                                        setOrderNumberInBook(+e.target.value)
                                    } else {
                                        setOrderNumberInBook(undefined)
                                    }
                                }}
                                placeholder="Order number in book..."
                                onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            />
                        </FormGroup>
                    </div>

                    <FormGroup>
                        <Label>Solving time in minutes</Label>
                        <Input
                            type="number"
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            value={solvingTimeInMinutes ?? "Solving time in minutes..."}
                            onChange={e => {
                                // bad checks. Should be fixed in the future!!!
                                if (solvingTimeInMinutes == null && e.target.value === '0') {
                                    setSolvingTimeInMinutes(undefined)
                                } else if (e.target.value.length > 0) {
                                    setSolvingTimeInMinutes(+e.target.value)
                                } else {
                                    setSolvingTimeInMinutes(undefined)
                                }
                            }}
                            placeholder="Solving time in minutes..."
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Difficulty</Label>
                        <p style={{ fontSize: '12px', marginBottom: '8px' }}>
                            Exercises with difficulty 1, 2, 3 will be declared as "{inLocalLanguage('easy')}" <br />
                            Exercises with difficulty 4, 5, 6, 7 will be declared as "{inLocalLanguage('medium')}"<br />
                            Exercises with difficulty 8, 9, 10 will be declared as "{inLocalLanguage('hard')}"
                        </p>
                        <UncontrolledDropdown>
                            <DropdownToggle caret outline>
                                {difficulty ?? 'Choose difficulty'}
                            </DropdownToggle>
                            <DropdownMenu>
                                {_difficulty.map((difficulty) => <DropdownItem
                                    key={difficulty}
                                    onClick={() => setDifficulty(difficulty)}
                                >
                                    {difficulty}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {difficulty != null &&
                            <span style={{ fontSize: '14px' }}>
                                This exercise will be declared as:&nbsp;
                                <span style={{ color: 'green', fontWeight: 'bold' }}>
                                    {
                                        difficulty <= 3 ? inLocalLanguage('easy') :
                                            difficulty <= 7 ? inLocalLanguage('medium') : inLocalLanguage('hard')
                                    }
                                </span>
                            </span>
                        }
                    </FormGroup>
                    <FormGroup>
                        <Label>Subject</Label>
                        <UncontrolledDropdown>
                            <DropdownToggle caret outline>
                                {subject?.subjectInLocal ?? 'Choose a subject'}
                            </DropdownToggle>
                            <DropdownMenu>
                                {checkNotNull(subjectsAuthority, '9iadsvB').map((subject, index) => <DropdownItem
                                    key={index}
                                    onClick={() => setSubject(subject)}
                                >
                                    {subject.subjectInLocal}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </FormGroup>
                    <div style={{ border: "1px solid gray" }}>
                        Exercise text:
                        <EotTextInList
                            eotText={params.eotText}
                            difficulty={undefined}
                            area={undefined}
                            exerciseOrderNumberInList={undefined}
                            onClick={() => { }}
                            timestamp={undefined}
                        />
                    </div>
                    {/* <div className='mg-10' style={{ border: "2px solid pink", padding: "5px" }}>
                        <p style={{ color: "brown" }}>
                            Suggest keywords based on <u>this input</u> and <u>exercise_text + additional_keywords</u>
                        </p>
                        <Input
                            type="textarea"
                            value={toSuggestKeywordsFrom}
                            onChange={(e) => {
                                setToSuggestKeywordsFrom(e.target.value)
                                setSuggestedKeywordsReport(undefined)
                            }}
                        />
                        <span
                            className='span-btn'
                            onClick={() => {
                                const currentKeywords = calculateKeywordsMap(params.eotText, additionalKeywords)

                                const keywordsFromInput: Map<string, number> = new Map()
                                toSuggestKeywordsFrom.split(" ").forEach(word => {
                                    const n = keywordsFromInput.get(word)
                                    keywordsFromInput.set(word, (n ?? 0) + 1)
                                })

                                const suggest: string[] = []

                                const keywordsFromInput_Arr = [...keywordsFromInput]

                                for (const [keyword, nAppearings] of keywordsFromInput_Arr) {
                                    const alreadyNExists = currentKeywords.get(keyword)

                                    let addTimes: number | undefined = undefined
                                    if (alreadyNExists == null) {
                                        addTimes = nAppearings
                                    } else if (nAppearings > alreadyNExists) {
                                        addTimes = nAppearings - alreadyNExists
                                    }

                                    if (addTimes != null) {
                                        for (let i = 1; i <= addTimes; i++) {
                                            suggest.push(keyword)
                                        }
                                    }
                                }

                                const shuffled = _.shuffle(suggest.map(it => it.toLowerCase()))

                                setSuggestedKeywordsReport(shuffled.join(" "))
                            }}
                        >
                            Suggest
                        </span>
                        {suggestedKeywordsReport != null && (
                            <div className='mg-5 pd-5' style={{ border: "1px solid green" }}>
                                <p>Suggested string length: {suggestedKeywordsReport.length}. Suggested:</p>
                                <p style={{ color: "#03c2fc" }}>{suggestedKeywordsReport}</p>
                                <span
                                    className='span-btn'
                                    onClick={() => {
                                        setAdditionalKeywordsHelper(`${additionalKeywords} ${suggestedKeywordsReport}`)
                                    }}
                                >
                                    Add suggested to additional keywords
                                </span>
                            </div>
                        )}
                    </div> */}
                    {/* <div className='mg-10' style={{ border: "2px solid blue", padding: "5px" }}>
                        <p>
                            Check if keyword exists. <br />
                            Keywords are NOT case sensitive (meaning that "XYZ" is same as "xyz"). <br />
                            Calculation is based on <u>this input</u> and <u>exercise_text + additional_keywords</u>
                        </p>
                        <input
                            type="text"
                            value={keywordToCheck}
                            onChange={(e) => setKeywordToCheckHelper(e.target.value)}
                        />
                        <span
                            className='span-btn'
                            onClick={() => {
                                const currentKeywords = calculateKeywordsMap(params.eotText, additionalKeywords)
                                const nAppearings = currentKeywords.get(keywordToCheck.toLowerCase())
                                setCheckedKeywordReport({
                                    checkedKeyword: keywordToCheck,
                                    nAppearings: nAppearings ?? 0,
                                })
                            }}
                        >
                            Check
                        </span>
                        {checkedKeywordReport != null && <div
                            className='mg-5'
                            style={{ padding: "5px", border: "1px solid orange" }}
                        >
                            <div>
                                Checked keyword: "<span style={{
                                    color: checkedKeywordReport.nAppearings === 0 ? "red" : "green",
                                    fontSize: "120%",
                                }}>
                                    {checkedKeywordReport.checkedKeyword}
                                </span>"
                            </div>
                            <div>
                                n appearings: <span style={{
                                    color: checkedKeywordReport.nAppearings === 0 ? "red" : "green",
                                    fontSize: "120%",
                                }}>
                                    {checkedKeywordReport.nAppearings}
                                </span>
                            </div>
                            <div>
                                <span
                                    className='span-btn'
                                    onClick={() => {
                                        setAdditionalKeywordsHelper(`${additionalKeywords} ${checkedKeywordReport.checkedKeyword.toLowerCase()}`)
                                    }}
                                >
                                    Add checked keyword to additional keywords
                                </span>
                            </div>
                        </div>}
                    </div> */}
                    <FormGroup>
                        <Label>Additional keywords</Label>
                        <Input
                            type="textarea"
                            value={additionalKeywords}
                            onChange={(e) => setAdditionalKeywordsHelper(e.target.value)}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {editExercise != null && '# NOTE: This will EDIT existing exercise!'}
                <Button
                    onClick={saveExercise}
                    disabled={!okFulfilled}
                >
                    {editExercise != null ? 'Save edited' : 'Save'}
                </Button>
            </ModalFooter>
        </>
    }

    return <div className="save-exercise-modal responsive-modal-box" style={{ width: "1080px" }} >
        {content}
    </div>
}
