import React from 'react'
import {
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap'
import { ModalContentProps } from '../utils/useModal'
import { TOKEN_NAME, queryServer } from '../utils/queryServer'
import { validate } from '../utils/Common/Common'
import { useLocalLanguage } from '../utils/ServiceLocal'
import { assertNever } from '../utils/utilFunctions'
import { Loader } from '../utils/Loader'

export const ChangePasswordModal = ({
    params,
    closeModal,
}: ModalContentProps<{}>) => {
    const [currentPassword, setCurrentPassword] = React.useState('')
    const [newPassword1, setNewPassword1] = React.useState('')
    const [newPassword2, setNewPassword2] = React.useState('')

    const [inputCurrentPasswordType, setInputCurrentPasswordType] = React.useState<'password' | 'text'>('password')
    const [inputNewPassword1Type, setInputNewPassword1Type] = React.useState<'password' | 'text'>('password')
    const [inputNewPassword2Type, setInputNewPassword2Type] = React.useState<'password' | 'text'>('password')

    const [loading, setLoading] = React.useState(false)

    const [errorMessage, setErrorMessage] = React.useState<string>()

    const [success, setSuccess] = React.useState(false)

    const { inLocalLanguage } = useLocalLanguage()

    const togglePasswordType = (
        inputPasswordType: 'password' | 'text',
        setInputPasswordType: React.Dispatch<React.SetStateAction<"password" | "text">>,
    ) => {
        return (
            <span
                onClick={() => inputPasswordType === 'password' ? setInputPasswordType('text') : setInputPasswordType('password')}
                style={{
                    position: 'absolute',
                    top: '30px',
                    right: '8px',
                    padding: '8px',
                    fontSize: '110%',
                    cursor: 'pointer',
                }}
            >
                {inputPasswordType === "password" ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
            </span>
        )
    }

    const changePassword = async () => {
        if (loading) {
            return
        } else if (currentPassword.length === 0) {
            setErrorMessage(inLocalLanguage("Please enter your current password"))
        } else if (newPassword1.length === 0) {
            setErrorMessage(inLocalLanguage("Please enter new password"))
        } else if (!validate(newPassword1, "password")) {
            setErrorMessage(inLocalLanguage("Your new password doesn't meet the password criteria - it needs to be at least 8 characters long"))
        } else if (newPassword1 !== newPassword2) {
            setErrorMessage(inLocalLanguage("Your new passwords doesn't match. Make sure to repeat the new password correctly."))
        } else {
            try {
                setErrorMessage(undefined)

                setLoading(true)

                const response = await queryServer(
                    'changePassword',
                    {
                        currentPassword: currentPassword,
                        newPassword: newPassword1
                    },
                )

                if (response.success === 'yes') {
                    window.localStorage.setItem(TOKEN_NAME, response.token)
                    setSuccess(true)
                } else if (response.success === 'no') {
                    if (response.reason === 'Wrong password') {
                        setErrorMessage(inLocalLanguage('Wrong password'))
                    } else {
                        assertNever(response.reason)
                    }
                } else {
                    assertNever(response)
                }
            } finally {
                setLoading(false)
            }
        }
    }

    const changePasswordForm = () => {
        return (<>
            <Form>
                <FormGroup style={{ position: 'relative' }}>
                    <Label>{inLocalLanguage('Please enter your current password')}</Label>
                    <Input
                        type={inputCurrentPasswordType}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        style={{ fontSize: "16px" }}
                    />
                    {togglePasswordType(inputCurrentPasswordType, setInputCurrentPasswordType)}
                </FormGroup>
                <FormGroup style={{ position: 'relative' }}>
                    <Label>{inLocalLanguage('Please enter new password')}</Label>
                    <Input
                        type={inputNewPassword1Type}
                        value={newPassword1}
                        onChange={(e) => setNewPassword1(e.target.value)}
                        style={{ fontSize: "16px" }}
                    />
                    {togglePasswordType(inputNewPassword1Type, setInputNewPassword1Type)}
                </FormGroup>
                <FormGroup style={{ position: 'relative' }}>
                    <Label>{inLocalLanguage('Please repeat new password')}</Label>
                    <Input
                        type={inputNewPassword2Type}
                        value={newPassword2}
                        onChange={(e) => setNewPassword2(e.target.value)}
                        style={{ fontSize: "16px" }}
                    />
                    {togglePasswordType(inputNewPassword2Type, setInputNewPassword2Type)}
                </FormGroup>
            </Form>
            {errorMessage != null && <div style={{
                color: 'red',
                padding: "10px",
                marginTop: "20px",
            }}>
                {errorMessage}
            </div>}
        </>)
    }

    const successMessage = () => {
        return <div
            style={{
                margin: '16px',
                fontSize: '18px',
                textAlign: 'center',
            }}
        >
            {inLocalLanguage('The password is updated successfully.')}🎉
        </div>
    }

    return <div className='responsive-modal-box' style={{ width: "560px" }}>
        <ModalHeader toggle={closeModal}>{inLocalLanguage('Change password')}</ModalHeader>
        <ModalBody>
            {success ? successMessage() : changePasswordForm()}
        </ModalBody>
        <ModalFooter>
            {success ?
                <Button onClick={closeModal} color="secondary">
                    Ok
                </Button> :
                <>
                    <Button onClick={changePassword} color="success">
                        {inLocalLanguage('Save')}
                        {loading && <Loader
                            className='mg-l-10'
                            style={{ width: '15px', height: '15px' }}
                        />}
                    </Button>
                    <Button onClick={closeModal} color="secondary">
                        {inLocalLanguage('Cancel')}
                    </Button>

                </>}
        </ModalFooter>
    </div>
}
