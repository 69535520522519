import React, { useContext } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { queryServer } from '../utils/queryServer'
import { Container } from "../utils/Container"
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { Eot } from './Eot'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'
import { Loader } from '../utils/Loader'
import { AppContext } from '../contexts/AppContext'

export const ExercisePage = () => {
    const [exercise, setExercise] = React.useState<RequestResponseTypes["getExercise"]["response"] | null>()

    const { exerciseId } = useParams()
    const [searchParams] = useSearchParams()

    const getExercise = async (exerciseId: string) => {
        const response = await queryServer(
            'getExercise',
            {
                exerciseId,
                notificationId: ENVIRONMENT_CALCULATED === 'prod' ? undefined : searchParams.get("notification") ?? undefined,
                openCommentId: ENVIRONMENT_CALCULATED === 'prod' ? undefined : searchParams.get("openCommentId") ?? undefined,
            },
        )
        setExercise(response)
    }

    const { gHigherAuthorityLevel } = useContext(AppContext)

    React.useEffect(() => {
        if (exerciseId != null) {
            getExercise(exerciseId)
        } else {
            setExercise(null)
        }
    }, [])

    let content
    if (exercise === undefined) {
        content = <Loader color='primary' />
    } else if (exercise === null) {
        content = <h5>Error (probably bad ID)</h5>
    } else {
        content = <Eot
            kind="exercise"
            eotId={exercise.id}
            textSolution={exercise.textSolution}
            text_public={exercise.exercise_text_public}
            video_solution={exercise.video_solution?.videoMetadata ?? null}
            subjectId={exercise.subjectId}
            initialyQueriedComments={exercise.initialyQueriedComments}
        />
    }

    return <Container
        title="Exercise page"
        className="exercise-page"
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: false,
        }}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
    >
        {gHigherAuthorityLevel === 10 && exercise?.exercise_text_photo_url != null && <div style={{
            borderBottom: "1px solid blue",
            marginBottom: "40px",
            paddingBottom: "40px",
        }}>
            <div>(image is visible only to higher authority contributors)</div>
            <img
                className='img-in-eot-solution'
                src={exercise.exercise_text_photo_url}
                alt="No, or bad image..."
            />
        </div>}
        {content}
    </Container>
}
