import React from 'react'
import { Container } from "../utils/Container"
import { queryServer } from "../utils/queryServer"
import { useLocalLanguage } from '../utils/ServiceLocal'
import { assertNever } from '../utils/utilFunctions'
import { HomeData } from './HomeData'
import { Loader } from '../utils/Loader'
import { updateHomePageIsRendered } from '../Header/Header'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'

export const HomePage = () => {
    const [homeData, setHomeData] = React.useState<RequestResponseTypes["home"]["response"]>()

    const { inLocalLanguage } = useLocalLanguage()

    React.useEffect(() => {
        updateHomePageIsRendered(true)
        return () => {
            updateHomePageIsRendered(false)
        }
    }, [])

    const getHomeData = async () => {
        const data = await queryServer(
            'home',
            undefined,
        )
        setHomeData(data)
    }

    React.useEffect(() => {
        getHomeData()
    }, [])

    let content
    if (homeData == null) {
        content = <Loader />
    } else if (homeData.type === 'no_data') {
        content = <h2>{inLocalLanguage("No data")}</h2>
    } else if (homeData.type === 'single_option') {
        content = <HomeData
            subjectsAndGrades={[{
                ...homeData.subject,
                grades: [{ ...homeData.grade }],
            }]}
            singleOptionData={{
                exercise: homeData.exercise,
                theory: homeData.theory,
                exerciseTests: homeData.exerciseTests,
            }}
        />
    } else if (homeData.type === 'multiple_options') {
        content = <HomeData
            subjectsAndGrades={homeData.subjectsAndGrades}
            singleOptionData={undefined}
        />
    } else {
        assertNever(homeData)
    }

    return <Container
        title={inLocalLanguage('Home page')}
        className="home-page"
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: false,
        }}
    >
        {content}
    </Container>
}
