import { getSignedPutUrl } from "./getSignedPutUrl"
import { check, checkNotNull } from "./utilFunctions"

export async function uploadFile(
    file: File,
    bucketName: string,
    onSuccess: (fileUrl: string) => Promise<void>,
): Promise<boolean> {
    try {
        const signedPutUrl = checkNotNull(await getSignedPutUrl(bucketName, file.type), ">>> signedPutUrl == null <<<")

        const uploadFileResult = await fetch(signedPutUrl, {
            method: "PUT",
            headers: { "Content-Type": file.type },
            body: file,
        })

        check(uploadFileResult.ok, ">>> uploadFileResult.ok <<<")
        const fileUrl = uploadFileResult.url.split("?")[0]

        await onSuccess(fileUrl)

        return true
    } catch (e) {
        console.log(e)
        return false
    }
}
