import React from 'react'
import { ExerciseInformationsForGrade, ExerciseOrTheory, TestInGrade, TheoryInformationsForGrade } from '../utils/Common/Common'
import { FindTheory } from './FindTheory'
import { FindExercise } from './FindExercise'
import { FormGroup, Label } from 'reactstrap'
import Select from 'react-select'
import { useLocalLanguage } from '../utils/ServiceLocal'
import { LAST_FILTERS } from './LastFilters'

type Props = {
    exercise: ExerciseInformationsForGrade
    theory: TheoryInformationsForGrade
    exerciseTests: TestInGrade[] | undefined
}

export const OrganizationForGrade = ({ exercise, theory, exerciseTests }: Props) => {
    const hasExercise = exercise.exercises.length > 0 && exercise.areaOrganization.length > 0
    const hasTheory = theory.areaOrganization.find(it => it.assigned_theory != null) != null

    const { inLocalLanguage } = useLocalLanguage()

    // this will be used only if both exercise and theory exist
    const [show, setShow] = React.useState<{ active: ExerciseOrTheory } | null>(() => {
        if (LAST_FILTERS.eot === "exercise" && hasExercise) {
            return { active: 'exercise' }
        } else if (LAST_FILTERS.eot === "theory" && hasTheory) {
            return { active: 'theory' }
        } else {
            LAST_FILTERS.eot = undefined
            return null
        }
    })

    const setShowHelper = (show: { active: ExerciseOrTheory } | null) => {
        LAST_FILTERS.eot = show?.active
        setShow(show)
    }

    if (hasExercise && hasTheory) {
        return <div className="wrapper-2">
            <FormGroup>
                <Label style={{ marginBottom: "2px" }}>{inLocalLanguage("Exercise or Theory")}</Label>
                <Select
                    isSearchable={false}
                    options={[{ active: 'exercise' }, { active: 'theory' }]}
                    value={show}
                    getOptionLabel={(option) => option.active === 'exercise' ? inLocalLanguage("Exercises") : inLocalLanguage("Theory")}
                    onChange={option => setShowHelper(option ?? null)}
                    isOptionSelected={option => option.active === show?.active}
                    placeholder={inLocalLanguage("Exercise or Theory")}
                    styles={{
                        control: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                        option: (base, r) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                    }}
                />
            </FormGroup>
            {show?.active === 'exercise' && <FindExercise exercise={exercise} exerciseTests={exerciseTests} />}
            {show?.active === 'theory' && <FindTheory theory={theory} />}
        </div>
    } else if (hasExercise) {
        return <FindExercise exercise={exercise} exerciseTests={exerciseTests} />
    } else if (hasTheory) {
        return <FindTheory theory={theory} />
    } else {
        // Should Not Happen In Production for non-contributor users (can happen, but shouldn't)
        return <h2>[snhip] {inLocalLanguage("No data")}</h2>
    }
}
