import React from 'react'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { ExerciseOrTheory } from '../utils/Common/Common'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { queryServer } from '../utils/queryServer'
import { ModalContentProps } from '../utils/useModal'
import { check } from '../utils/utilFunctions'
import { useLocalLanguage } from '../utils/ServiceLocal'

type Props = {
    collectionId: string
    itemId: string
    itIs: ExerciseOrTheory
    setSavedCollection: React.Dispatch<React.SetStateAction<RequestResponseTypes['savedCollection']['response'] | undefined>>
}

export const DeleteCollectionItemModal = ({
    params,
    closeModal,
}: ModalContentProps<Props>) => {
    const {
        itemId,
        itIs,
        collectionId,
        setSavedCollection
    } = params

    const { inLocalLanguage } = useLocalLanguage()

    const deleteCollectionItem = async () => {
        await queryServer(
            'deleteCollectionItem',
            { collectionId, itemId },
        )

        setSavedCollection(prev => {
            check(prev != null && prev.message === 'ok')
            return {
                message: 'ok',
                savedCollection: {
                    ...prev.savedCollection,
                    itemsInCollection: prev.savedCollection.itemsInCollection.filter(it => it.itemId !== itemId)
                }
            }
        })

        closeModal()
    }

    return <div className='responsive-modal-box' style={{ width: "480px" }}>
        <ModalHeader toggle={closeModal}>
            {inLocalLanguage("Delete")} {itIs == 'exercise' ? inLocalLanguage("Exercise") : inLocalLanguage("Theory")}
        </ModalHeader>
        <ModalBody>
            {inLocalLanguage("Delete")} {itIs == 'exercise' ? inLocalLanguage("Exercise").toLowerCase() : inLocalLanguage("Theory").toLowerCase()}?
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={deleteCollectionItem}>{inLocalLanguage("Delete")}</Button>
            <Button color="secondary" onClick={closeModal}>{inLocalLanguage("Cancel")}</Button>
        </ModalFooter>
    </div>
}
