import React from "react"
import { AppContext } from "../contexts/AppContext"
import { COUNTRIES, CountryId, LanguageInEnglish } from "./Common/Common"
import { checkNotNull } from "./utilFunctions"

export const DEFAULT_COUNTRY: CountryId = 'srbija-001'

type PossibleWebAppText =
    'Login' |
    'Logout' |
    `Register` |
    `Change password` |
    'Enter your email' |
    'Enter your password' |
    `Don't have account?` |
    `Have account?` |
    `Firstname` |
    `Lastname` |
    `Password` |
    `Home page` |
    `Home` |
    "Profile" |
    "Saved" |
    "Notifications" |
    "Change profile picture" |
    "Chose" |
    "Send" |
    "Save" |
    "Subjects" |
    "Firstname field cannot be empty" |
    "Lastname field cannot be empty" |
    "unknown_email" |
    "succesfuly_requested_reset_password" |
    "Email should be in valid format" |
    "Password length needs to be at least 8 characters long" |
    "Successfully created account" |
    "Validate your account by clicking the link we sent you at" |
    "Unknown register error. Please try again later." |
    'Error. Please try again later' |
    'Email is required' |
    'Password is required' |
    'No data' |
    'Please wait...' |
    'Subject' |
    'Level' |
    'Exercise or Theory' |
    'Exercises' |
    'Exercise' |
    'Theory' |
    'Area' |
    "Keywords" |
    "Find_exercise_by_keywords" |
    "Find_theory_by_keywords" |
    "Reached collection title maximum length" |
    "Reached collection description maximum length" |
    "Private collection" |
    "Public collection" |
    "nextPage" |
    "previousPage" |
    "Reached max collections/user" |
    "Collection title required" |
    "Reached max items/collection" |
    "Select collection" |
    "Already saved" |
    "In" |
    "Collection title" |
    "Collection description (optional)" |
    "Find collection" |
    "In new collection" |
    "In existing collection" |
    "Cancel" |
    "Collections saved by you" |
    "Collections saved by" |
    "Delete" |
    "Collection_u" |
    "Edit" |
    "You dont have saved collections" |
    "doesnt have saved collections" |
    "Forgot password" |
    "SendPasswordResetToEmail" |
    "Please enter your current password" |
    "Please enter new password" |
    "Please repeat new password" |
    "Wrong password" |
    "Your new password doesn't meet the password criteria - it needs to be at least 8 characters long" |
    "Your new passwords doesn't match. Make sure to repeat the new password correctly." |
    "The password is updated successfully." |
    "Settings" |
    "Enter title" |
    "Collection visibility" |
    "Collection doesn't exist" |
    "Not access to this private collection" |
    "Empty collection" |
    "Your saved collection" |
    "Saved collection of user " |
    "Difficulty" |
    "easy" |
    "medium" |
    "hard" |
    "check-verification-email-at-spam" |
    "Contact" |
    "All exercises" |
    "Tests"

const webAppTextsByCountries: Record<PossibleWebAppText, Record<LanguageInEnglish, string>> = {
    'check-verification-email-at-spam': {
        English: "If you don't see verification email at inbox, also check spam/junk",
        Serbian: "Ukoliko ne vidiš verifikacioni email u inboxu, proveri i neželjeno sanduče (spam/junk)",
    },
    "Collection_u": {
        English: "Collection",
        Serbian: "Kolekciju",
    },
    'nextPage': {
        English: "Next page",
        Serbian: "Sledeća strana",
    },
    'previousPage': {
        English: "Previous page",
        Serbian: "Prethodna strana",
    },
    "SendPasswordResetToEmail": {
        English: "Enter your lakoje.com email address, on which we will send you instructions for password reset",
        Serbian: "Unesi svoj email sa kojim je napravljen nalog na lakoje.com. Na taj email ćemo ti poslati instrukcije za resetovanje šifre",
    },
    "Forgot password": {
        English: "Forgot password",
        Serbian: "Zaboravljena šifra",
    },
    'doesnt have saved collections': {
        English: "doesn't have saved collections yet",
        Serbian: "nema sačuvane kolekcije",
    },
    "You dont have saved collections": {
        English: "You don't have saved collections yet",
        Serbian: "Nemaš sačuvane kolekcije",
    },
    'Delete': {
        English: 'Delete',
        Serbian: "Obriši",
    },
    'Edit': {
        English: 'Edit',
        Serbian: "Izmeni",
    },
    'Collections saved by': {
        English: 'Collections saved by',
        Serbian: "Sačuvane kolekcije korisnika",
    },
    'Collections saved by you': {
        English: 'Collections saved by you',
        Serbian: "Tvoje sačuvane kolekcije",
    },
    "succesfuly_requested_reset_password": {
        English: "We sent you directions on how to reset the password on your email",
        Serbian: "Poslali smo ti instrukcije kako da resetuješ šifru na tvoj email",
    },
    "unknown_email": {
        English: "Unknown email address",
        Serbian: "Nepoznata email adresa",
    },
    'Cancel': {
        English: 'Cancel',
        Serbian: "Otkaži",
    },
    'In new collection': {
        English: 'In new collection',
        Serbian: "U novoj kolekciji",
    },
    'In existing collection': {
        English: 'In existing collection',
        Serbian: "U postojećoj kolekciji",
    },
    'Find collection': {
        English: 'Find collection by title or collection description',
        Serbian: "Pronađi kolekciju po naslovu ili opisu kolekcije",
    },
    'Collection description (optional)': {
        English: 'Collection description',
        Serbian: "Opis kolekcije",
    },
    'Collection title': {
        English: 'Collection title',
        Serbian: "Naslov kolekcije",
    },
    'In': {
        English: 'In',
        Serbian: "U",
    },
    'Already saved': {
        English: 'Already saved',
        Serbian: "Već je snimljen",
    },
    'Reached max items/collection': {
        English: 'Reached maximum number of allowed items in selected collection. Please select another collection, or create a new one',
        Serbian: "Dostignut maksimum za broj dozvoljenih elemenata u odabranoj kolekciji. Odaberi drugu kolekciju, ili napravi novu.",
    },
    'Collection title required': {
        English: 'Collection title is required',
        Serbian: "Naslov kolekcije je obavezan",
    },
    'Select collection': {
        English: 'Select collection',
        Serbian: "Odaberi kolekciju",
    },
    'Reached collection title maximum length': {
        English: 'Reached collection title maximum length',
        Serbian: "Dostignuta maksimalna dužina za naslov kolekcije",
    },
    'Reached collection description maximum length': {
        English: 'Reached collection description maximum length',
        Serbian: "Dostignuta maksimalna dužina za opis kolekcije",
    },
    'Private collection': {
        English: 'Private collection',
        Serbian: "Privatna kolekcija",
    },
    'Public collection': {
        English: 'Public collection',
        Serbian: "Javna kolekcija",
    },
    'Reached max collections/user': {
        English: 'Reached maximum number of saved collections',
        Serbian: "Dostignut maksimum za broj dozvoljenih novih kolekcija",
    },
    'Login': {
        English: 'Login',
        Serbian: "Uloguj se",
    },
    'Logout': {
        English: 'Log out',
        Serbian: "Izloguj se",
    },
    'Register': {
        English: 'Register',
        Serbian: "Registruj se",
    },
    'Change password': {
        English: 'Change password',
        Serbian: "Promeni šifru",
    },
    "Don't have account?": {
        English: "Don't have account?",
        Serbian: "Nemaš nalog?",
    },
    "Have account?": {
        English: 'Have account?',
        Serbian: "Imaš nalog?",
    },
    "Enter your email": {
        English: 'Enter your email',
        Serbian: "Unesi email",
    },
    "Enter your password": {
        English: 'Enter your password',
        Serbian: "Unesi šifru",
    },
    "Firstname": {
        English: 'Firstname',
        Serbian: "Ime",
    },
    "Lastname": {
        English: 'Lastname',
        Serbian: "Prezime",
    },
    "Password": {
        English: 'Password',
        Serbian: "Šifra",
    },
    "Home page": {
        English: 'Home page',
        Serbian: "Početna strana",
    },
    "Home": {
        English: 'Home',
        Serbian: "Početna",
    },
    "Profile": {
        English: 'Profile',
        Serbian: "Profil",
    },
    "Saved": {
        English: 'Saved',
        Serbian: "Sačuvano",
    },
    "Notifications": {
        English: 'Notifications',
        Serbian: "Notifikacije",
    },
    "Change profile picture": {
        English: "Change profile picture",
        Serbian: "Promeni profilnu sliku",
    },
    "Chose": {
        English: "Chose",
        Serbian: "Odaberi",
    },
    "Send": {
        English: "Send",
        Serbian: "Pošalji",
    },
    "Save": {
        English: "Save",
        Serbian: "Sačuvaj",
    },
    "Subjects": {
        English: "Subjects",
        Serbian: "Predmeti",
    },
    "Firstname field cannot be empty": {
        English: "Firstname field cannot be empty",
        Serbian: "Polje za ime ne sme biti prazno",
    },
    "Lastname field cannot be empty": {
        English: "Lastname field cannot be empty",
        Serbian: "Polje za prezime ne sme biti prazno",
    },
    "Email should be in valid format": {
        English: "Email should be in valid format",
        Serbian: "Email adresa treba biti u validnom formatu",
    },
    "Password length needs to be at least 8 characters long": {
        English: "Password length needs to be at least 8 characters long",
        Serbian: "Dužina šifre mora biti minimum 8 karaktera",
    },
    "Successfully created account": {
        English: "Successfully created account",
        Serbian: "Uspešno napravljen nalog",
    },
    "Validate your account by clicking the link we sent you at": {
        English: "Validate your account by clicking the link we sent you at",
        Serbian: "Validiraj svoj nalog tako što ćes kliknuti na link koji smo ti poslali na",
    },
    "Unknown register error. Please try again later.": {
        English: "Unknown register error. Please try again later.",
        Serbian: "Nepoznata greška prilikom registracije. Pokušaj kasnije ponovo.",
    },
    'Error. Please try again later': {
        English: 'Error happened. Please try again later.',
        Serbian: "Desila se greška prilikom zahteva. Pokušaj kasnije ponovo.",
    },
    'Email is required': {
        English: 'Email is required',
        Serbian: "Polje za email mora biti popunjeno",
    },
    'Password is required': {
        English: 'Password is required',
        Serbian: "Polje za šifru mora biti popunjeno",
    },
    'No data': {
        English: 'No data',
        Serbian: "Nema podataka",
    },
    'Please wait...': {
        English: 'Please wait...',
        Serbian: "Molimo sačekajte...",
    },
    'Subject': {
        English: 'Subject',
        Serbian: "Predmet",
    },
    'Level': {
        English: 'Level',
        Serbian: "Nivo",
    },
    "Exercise or Theory": {
        English: 'Exercise or Theory',
        Serbian: "Zadaci ili Teorija",
    },
    'Exercises': {
        English: 'Exercises',
        Serbian: "Zadaci",
    },
    'Exercise': {
        English: 'Exercise',
        Serbian: "Zadatak",
    },
    'Theory': {
        English: 'Theory',
        Serbian: "Teorija",
    },
    'Area': {
        English: 'Area',
        Serbian: "Oblast",
    },
    "Keywords": {
        English: 'Keywords',
        Serbian: "Ključne reči",
    },
    "Find_exercise_by_keywords": {
        English: 'Find exercise by keywords',
        Serbian: "Pronađi zadatak po ključnim rečima",
    },
    "Find_theory_by_keywords": {
        English: 'Find theory by keywords',
        Serbian: "Pronađi teoriju po ključnim rečima",
    },
    "Please enter your current password": {
        English: 'Please enter your current password',
        Serbian: "Unesi tvoju trenutnu šifru",
    },
    "Please enter new password": {
        English: 'Please enter new password',
        Serbian: "Unesi novu šifru",
    },
    "Please repeat new password": {
        English: 'Please repeat new password',
        Serbian: "Ponovi novu šifru"
    },
    "Wrong password": {
        English: 'Wrong current password',
        Serbian: "Unesena trenutna šifra nije tačna"
    },
    "Your new password doesn't meet the password criteria - it needs to be at least 8 characters long": {
        English: 'Your new password doesn\'t meet the password criteria - it needs to be at least 8 characters long',
        Serbian: "Tvoja nova šifra ne ispunjava kriterijume - mora biti duga najmanje 8 karaktera"
    },
    "Your new passwords doesn't match. Make sure to repeat the new password correctly.": {
        English: 'Your new passwords doesn\'t match.Make sure to repeat the new password correctly.',
        Serbian: "Tvoje nove šifre se ne podudaraju. Unesi novu lozinku ispravno."
    },
    "The password is updated successfully.": {
        English: 'The password is updated successfully.',
        Serbian: 'Uspešno promenjena šifra.'
    },
    "Settings": {
        English: 'Settings',
        Serbian: "Podešavanja"
    },
    "Enter title": {
        English: 'Enter title',
        Serbian: "Unesi naslov"
    },
    "Collection visibility": {
        English: "Collection visibility",
        Serbian: 'Vidljivost kolekcije'
    },
    "Collection doesn't exist": {
        English: "Collection doesn't exist",
        Serbian: 'Kolekcija ne postoji'
    },
    "Not access to this private collection": {
        English: "Not access to this private collection",
        Serbian: 'Nemaš pristupa ovoj privatnoj kolekciji'
    },
    "Empty collection": {
        English: "Empty collection",
        Serbian: "Prazna kolekcija"
    },
    "Your saved collection": {
        English: "Your saved collection",
        Serbian: 'Tvoja sačuvana kolekcija'
    },
    "Saved collection of user ": {
        English: "Saved collection of user ",
        Serbian: "Sačuvana kolkecija korisnika "
    },
    "Difficulty": {
        English: "Difficulty",
        Serbian: "Težina"
    },
    "easy": {
        English: 'Easy',
        Serbian: 'Lako'
    },
    "medium": {
        English: 'Medium',
        Serbian: 'Srednje'
    },
    "hard": {
        English: 'Hard',
        Serbian: 'Teško'
    },
    "Contact": {
        English: 'Contact',
        Serbian: 'Kontakt'
    },
    "All exercises": {
        English: 'All exercises',
        Serbian: 'Svi zadaci'
    },
    "Tests": {
        English: 'Tests',
        Serbian: 'Testovi'
    }
}

export const useLocalLanguage = () => {
    const { gCountryId } = React.useContext(AppContext)

    const language: LanguageInEnglish = React.useMemo(() => {
        return checkNotNull(COUNTRIES.find(it => it.id === gCountryId), 'ycb7YdF').language_in_english
    }, [gCountryId])

    const inLocalLanguage = (text: PossibleWebAppText) => {
        return webAppTextsByCountries[text][language]
    }

    return {
        inLocalLanguage,
    }
}
