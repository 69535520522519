import React from 'react'
import { Link } from 'react-router-dom'
import { queryServer } from '../utils/queryServer'
import { useLocalLanguage } from "../utils/ServiceLocal"
import { Loader } from '../utils/Loader'
import { assertNever } from '../utils/utilFunctions'

export function ForgotPassword() {
    const [email, setEmail] = React.useState<string>('')

    const [message, setMessage] = React.useState<JSX.Element>()

    const [showRedBordersAndEmptyMessages, setShowRedBordersAndEmptyMessages] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    const { inLocalLanguage } = useLocalLanguage()

    const emailIsEmpty = email.trim() === ""

    const send = async () => {
        setMessage(undefined)

        if (loading) {
            return
        } else if (emailIsEmpty) {
            if (!showRedBordersAndEmptyMessages) {
                setShowRedBordersAndEmptyMessages(true)
            }
        } else {
            try {
                setLoading(true)

                const response = await queryServer(
                    'forgotPassword',
                    { email },
                )

                if (response.success === 'yes') {
                    setMessage(<span style={{ color: "green", fontSize: "116%" }}>
                        {inLocalLanguage("succesfuly_requested_reset_password")} "<b>{email}</b>"
                    </span>)
                    setEmail('')
                    setShowRedBordersAndEmptyMessages(false)
                } else if (response.success === 'no') {
                    if (response.reason === "UNKNOWN_EMAIL") {
                        setMessage(<span style={{ color: "red", fontSize: "108%" }}>
                            {inLocalLanguage("unknown_email")} "<b>{email}</b>"
                        </span>)
                    } else {
                        assertNever(response.reason)
                    }
                } else {
                    assertNever(response)
                }
            } catch (err: any) {
                setMessage(<div style={{ color: 'red' }}>
                    {err.message ?? inLocalLanguage("Error. Please try again later")}
                </div>)
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <div className="signin-wrapper app-background">
            <div className="signin-box">
                <h2 className="slim-logo logo-font" style={{ color: '#1b84e7', marginBottom: 20, fontSize: "52px" }}>Lako je</h2>
                <h3 className="signin-title-primary mg-b-20">{inLocalLanguage("Forgot password")}</h3>

                <div className="form-group">
                    <div className='mg-b-5' style={{ fontSize: "106%" }}>
                        {inLocalLanguage("SendPasswordResetToEmail")}
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={inLocalLanguage('Enter your email')}
                        value={email}
                        onChange={e => setEmail(e.target.value.trim())}
                        style={{
                            fontSize: "16px",
                            border: showRedBordersAndEmptyMessages && emailIsEmpty ? "1px solid red" : undefined
                        }}
                    />
                    {showRedBordersAndEmptyMessages && emailIsEmpty && <span style={{ fontSize: "92%" }}>
                        {inLocalLanguage('Email is required')}
                    </span>}
                </div>

                {message && <div className='mg-b-10'>{message}</div>}

                <button
                    className="btn btn-primary btn-block btn-signin mg-t-30"
                    onClick={send}
                >
                    {inLocalLanguage("Send")}
                    {loading && <Loader
                        className='mg-l-10'
                        style={{ width: '15px', height: '15px' }}
                    />}
                </button>
                <hr />
                <p className="mg-b-0">
                    <Link to="/login">{inLocalLanguage("Login")}</Link>
                </p>
            </div>
        </div>
    )
}
