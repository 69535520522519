import React from 'react'
import { Container } from "../utils/Container"
import { useDropzone } from 'react-dropzone'

export const ScanExercise = () => {
    const [image, setImage] = React.useState<File & { preview: string }>()

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setImage(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))[0])
        },
    })

    const getDropboxBorderColor = () => {
        if (isDragAccept) {
            return '#00e676'
        }
        if (isDragReject) {
            return '#ff1744'
        }
        return 'grey'
    }

    return <Container
        title={"Contribute"}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: false,
        }}
    >
        <section className="container">
            <div {...getRootProps({ className: 'dropbox' })} style={{ borderColor: `${getDropboxBorderColor()}` }}>
                <input {...getInputProps()} />
                {
                    isDragActive
                        ? <p>Drop the image here...</p>
                        : <p>
                            {/* Upload image of a Math exercise */}
                            Drag and drop or click to select image of a math exercise
                            {/* {' '}<i className="fa fa-upload" aria-hidden="true" style={{ color: '#6c757d' }}></i> */}
                        </p>
                }
            </div>
        </section>
        {image && <div style={{ padding: '0 15px', width: '100%' }}>
            <img
                src={image.preview}
                className='mw-100'
            />
        </div>}
    </Container>
}
